<template>
  <div>
    <v-row>
      <v-col cols="12" md="8" lg="7">
        <common-card elevation="0">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="2">
                <v-responsive
                  :aspect-ratio="1 / 1"
                  class="align-center"
                  :width="$vuetify.breakpoint.mdAndUp ? '100%' : '50%'"
                  style="margin: 0 auto;"
                >
                  <v-img :src="cotizacionSelected.logo" contain></v-img>
                </v-responsive>
              </v-col>
              <v-col cols="12" md="5">
                <div>
                  <span class="subtitulo-header-2 font-montserrat"
                    >Modalidad de pago</span
                  >
                  <v-select
                    v-model="cotizacionSelected.tipoPago"
                    :items="tiposPago"
                    item-text="nombre"
                    item-value="inter"
                    outlined
                    disabled
                    class="mt-2"
                  ></v-select>
                </div>
              </v-col>
              <v-col cols="12" md="5">
                <div>
                  <span class="subtitulo-header-2 font-montserrat"
                    >Tipo de plan</span
                  >
                  <v-select
                    v-model="cotizacionSelected.plan"
                    :items="tiposPlan"
                    item-text="nombre"
                    item-value="id"
                    outlined
                    disabled
                    class="mt-2"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </common-card>

        <div class="mt-5">
          <v-row
            v-for="(coverage, index) in cotizacionSelected.coverages"
            :key="index"
            class="mt-2"
          >
            <v-col cols="12" md="9">
              <common-card elevation="0">
                <v-card-text>
                  <v-row>
                    <v-col cols="2">
                      <v-responsive
                        :aspect-ratio="1"
                        class="justify-center align-center"
                        style="text-align: center;"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          style="margin: 0 auto;"
                        >
                          <path
                            d="M36.9297 14.8066C35.8937 14.148 34.5992 13.6691 33.1734 13.4059C32.9332 13.3599 32.7117 13.2444 32.5364 13.0737C32.3612 12.903 32.2399 12.6847 32.1875 12.4457C31.5734 9.74961 30.2656 7.43555 28.3477 5.68164C26.1055 3.63086 23.1414 2.50195 20 2.50195C17.2383 2.50195 14.6875 3.36758 12.6273 5.00195C11.2359 6.1113 10.1152 7.52271 9.35 9.1293C9.26488 9.31046 9.13762 9.46861 8.97887 9.59052C8.82012 9.71244 8.63449 9.79458 8.4375 9.83008C6.31797 10.2129 4.48984 10.977 3.09297 12.0723C1.07031 13.6605 0 15.9051 0 18.5645C0 23.8301 4.36953 27.502 10.625 27.502H18.75V17.5371C18.75 16.8645 19.2672 16.2871 19.9398 16.2535C20.1089 16.2454 20.2778 16.2716 20.4363 16.3307C20.5949 16.3898 20.7398 16.4804 20.8623 16.5971C20.9848 16.7139 21.0823 16.8542 21.149 17.0098C21.2156 17.1653 21.25 17.3327 21.25 17.502V27.502H30.9375C36.6125 27.502 40 24.827 40 20.3457C40 17.998 38.9383 16.0824 36.9297 14.8066Z"
                            fill="#029ECF"
                          />
                          <path
                            d="M18.7488 33.2379L15.8816 30.3754C15.6458 30.1469 15.3296 30.0203 15.0012 30.0231C14.6729 30.0258 14.3588 30.1577 14.1269 30.3901C13.8949 30.6226 13.7638 30.9369 13.7617 31.2653C13.7597 31.5937 13.887 31.9096 14.116 32.1449L19.116 37.1363C19.3503 37.3701 19.6678 37.5014 19.9988 37.5014C20.3298 37.5014 20.6473 37.3701 20.8816 37.1363L25.8816 32.1449C26.1107 31.9096 26.2379 31.5937 26.2359 31.2653C26.2339 30.9369 26.1027 30.6226 25.8708 30.3901C25.6389 30.1577 25.3248 30.0258 24.9964 30.0231C24.6681 30.0203 24.3518 30.1469 24.116 30.3754L21.2488 33.2379V27.502H18.7488V33.2379Z"
                            fill="#029ECF"
                          />
                        </svg>
                      </v-responsive>
                    </v-col>
                    <v-col
                      cols="10"
                      class="d-flex flex-column align-center no-wrap"
                    >
                      <div style="width: 100%;">
                        <span class="subtitulo-header-2 font-montserrat">{{
                          coverage.name
                        }}</span>
                      </div>
                      <div>
                        <span class="text-body-1">{{
                          coverage.description
                        }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </common-card>
            </v-col>
            <v-col cols="12" md="3">
              <common-card
                elevation="0"
                style="background-color: rgba(192, 219, 226, 0.50); width: 100%; height: 100%;"
                class="d-flex align-center py-2"
              >
                <div style="text-align: center; width: 100%;">
                  <div style="width: 100%">
                    <span class="font-montserrat">{{
                      getCoverageLabels(coverage).title
                    }}</span>
                  </div>
                  <div style="width: 100%" class="">
                    <span class="font-weight-bold font-montserrat">{{
                      getCoverageLabels(coverage).value
                    }}</span>
                  </div>
                </div>
              </common-card>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col cols="12" md="4" lg="5">
        <v-row>
          <v-col cols="12">
            <common-card>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <span class="subtitulo-header-2 font-montserrat"
                      >Cotización</span
                    >
                  </v-col>
                  <v-col cols="6">
                    <span class="subtitulo-header-2 font-montserrat"
                      >#{{ cotizacionSelected.cotizacion_general }}</span
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </common-card>
          </v-col>

          <v-col cols="12">
            <common-card>
              <v-card-text>
                <div>
                  <span class="subtitulo-header-2 font-montserrat">
                    Vehículo
                  </span>
                </div>
                <v-row>
                  <v-col cols="3">
                    <span class="font-montserrat">
                      Marca
                    </span>
                  </v-col>
                  <v-col cols="9">
                    <span class="font-montserrat font-weight-bold">
                      {{ carPrice.carBrand.brand }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <span class="font-montserrat">
                      Modelo
                    </span>
                  </v-col>
                  <v-col cols="9">
                    <span class="font-montserrat font-weight-bold">
                      {{ carPrice.carBrand.model.description }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <span class="font-montserrat">
                      Año
                    </span>
                  </v-col>
                  <v-col cols="9">
                    <span class="font-montserrat font-weight-bold">
                      {{ carPrice.carBrand.model.model }}
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </common-card>
          </v-col>

          <v-col cols="12">
            <common-card>
              <v-card-text class="font-montserrat">
                <v-row>
                  <v-col cols="6">
                    <span>Costo anual</span>
                  </v-col>
                  <v-col cols="6">
                    <span class="font-weight-bold">{{
                      currencyFormatt(cotizacionSelected.price)
                    }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </common-card>
          </v-col>

          <v-col cols="12">
            <v-btn
              block
              dark
              class="common-botton"
              @click="continueToEmission()"
            >
              <span>Emitir</span>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              block
              dark
              rounded outlined color="#00a7e4"
              @click="$router.go(-1)"
            >
              <span>Regresar</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";

export default {
  components: {
    CommonCard,
  },

  data() {
    return {
      cotizacionSelected: JSON.parse(
        sessionStorage.getItem("cotizacionSelected")
      ),
      carPrice: JSON.parse(sessionStorage.getItem("carPrice")),
      tiposPlan: [
        {
          id: "AMP",
          nombre: "AMPLIA",
        },
        {
          id: "RC",
          nombre: "RESPONSABILIDAD CIVIL",
        },
        {
          id: "LIMIT",
          nombre: "LIMITADA",
        },
      ],
      tiposPago: JSON.parse(sessionStorage.getItem("datosPago")),
    };
  },

  methods: {
    getCoverageLabels(coverage) {
      console.log({ coverage });
      if (coverage.deductive)
        return {
          title: "DEDUCIBLE",
          value: `${coverage.deductive}%`,
        };
      if (coverage.sum_assured && coverage.sum_assured != "A")
        return {
          title: "COBERTURA DE HASTA",
          value: `${this.currencyFormatt(coverage.sum_assured)}`,
        };
      if (coverage.sum_assured)
        return {
          value: "AMPARADA",
        };

      return {
        value: "AMPARADA",
      };
    },

    currencyFormatt(val) {
      const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      });

      return formatoMonedaMXN.format(val);
    },

    continueToEmission() {
      this.$router.push("/emision/vehiculo");
      window.dataLayer.push({
        event: 'ITFY_auto_emitir',
        no_cotizacion: this.cotizacionSelected.cotizacion_general,
        userID: localStorage.getItem('agenteMail'),
        plan: this.cotizacionSelected.plan,
        modalidad_pago: this.cotizacionSelected.tipoPago,
        aseguradora: this.cotizacionSelected.insurer,
        total_pago: this.cotizacionSelected.price,
        boton_contacto: 'Emitir'
      })
    },
  },
  mounted () {
    const emissionData = JSON.parse(sessionStorage.getItem('contractorData'))
    window.dataLayer.push({
      event: 'ITFY_auto_emitir_v',
      no_cotizacion: this.cotizacionSelected.cotizacion_general,
      userID: localStorage.agenteMail,
      correo: emissionData?.emission?.contractorData?.email,
      telefono: emissionData?.emission?.contractorData?.phone,
    });
  }
};
</script>
